
  import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
  import {
    PermissionDefinitionCreateOrUpdateDto, PermissionDefinitionDto,
    PermissionDefinitionScope, SelectListItem, StringStringKeyValuePair,
  } from "@/api/appService";
  import {ElForm} from "element-ui/types/form";
  import api from '@/api';

  interface PermissionScopeMapping {
    name: PermissionDefinitionScope;
    label: string
  }

  interface SvgIconItem {
    key: string;
    value: string
  }

  enum PermissionCreateState{
    'ready'='ready',
    'creating'='creating',
    'created'='created',

    'failed'='failed'
  }


  interface PermissionCreate {
    permission:SelectListItem,
    state:PermissionCreateState;   // ready,creating,created,failed
  }

  @Component({name: 'FastCreatePermission'})
  export default class FastCreatePermission extends Vue {
    @Ref() readonly dataForm!: ElForm;

    @Prop({required: false,default:{}})
    parentPermission?:PermissionDefinitionDto;

    spliceChar:string ='';



    permissions:PermissionCreate[] = [];

    show = false;

    defaultNames:SelectListItem[] = [];

    successCount = 0;




    created() {
      this.defaultNames.push({text:'创建',value:'Create'});
      this.defaultNames.push({text:'修改',value:"Edit"});
      this.defaultNames.push({text:'删除',value:'Delete'});
    }

    @Watch('parentPermission')
    handleParentPermissionChange(permission?:PermissionDefinitionDto){
      if(permission && permission.name){
        this.spliceChar = permission.name.lastIndexOf('.')>0?':':'';
        if(permission.name.indexOf('_')>0){
          this.spliceChar = '_';
        }
        if(!this.spliceChar){
          this.spliceChar = '.';
        }
      } else {
        this.spliceChar = '.';
      }
    }

    getIconStatus(state:PermissionCreateState){
      return {
        'el-icon-loading':(state === PermissionCreateState.creating),
        'el-icon-circle-close':(state === PermissionCreateState.failed),
        'el-icon-circle-check':(state === PermissionCreateState.created),
        'el-icon-edit':(state === PermissionCreateState.ready)
      }
    }


    @Watch('show')
    onShowChange(value: boolean) {
      if (value && this.parentPermission && this.parentPermission.name) {
         this.permissions = [];

         this.defaultNames.forEach((item,index)=>{
            this.createItem(item);
         });

      }

      this.$nextTick(() => {
        this.dataForm.clearValidate();
      });
    }

    handleDelete(index:number){
      this.permissions.splice(index,1);
    }

    handleCreateItem(){
      this.createItem({text:'',value:''});
    }

    createItem(item:SelectListItem){
      this.permissions.push({permission:item,state:PermissionCreateState.ready});
    }

    @Watch('successCount')
    handleSuccessCountChange(newValue:number){
      if(newValue!==0 && newValue === this.permissions.length){
        this.show = false;
        this.$message.success('全部添加成功');
        this.$emit('all-completed');
      }
    }

    async save() {
      this.successCount = 0;
      this.permissions.forEach((item,index)=>{
        this.saveItem(item,index);
      });
    }

    saveItem(item:PermissionCreate,index:number){

      if(item.state === PermissionCreateState.ready || item.state === PermissionCreateState.failed){

        const permission:PermissionDefinitionCreateOrUpdateDto = {
          parentId: this.parentPermission!.id,
          isHiddenForMenu:true,
          displayName:item!.permission!.text,
          displayOrder:index+1,
          icon:'',
          id:0,
          name:`${this.parentPermission!.name}${this.spliceChar}${item!.permission.value}`,
          permissionDefinitionScopes:this.parentPermission!.permissionDefinitionScopes,
          url:''
        }

        item.state = PermissionCreateState.creating;
        api.permissionDefinition.create({body:permission}).then(res=>{
          item.state = PermissionCreateState.created;
          this.successCount ++;
        }).catch(res=>{
          item.state = PermissionCreateState.failed;
        });
      } else {
        this.successCount ++;
      }
    }

    cancel() {
      this.show = false;
    }



  }
