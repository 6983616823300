
  import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
  import {
    PermissionDefinitionCreateOrUpdateDto,
    PermissionDefinitionScope, SelectListItem, StringStringKeyValuePair,
  } from "@/api/appService";
  import {ElForm} from "element-ui/types/form";
  import api from '@/api';

  interface PermissionScopeMapping {
    name: PermissionDefinitionScope;
    label: string
  }

  interface SvgIconItem {
    key: string;
    value: string
  }

  @Component({name: 'PermissionDefinitionEdit'})
  export default class PermissionDefinitionEdit extends Vue {
    @Ref() readonly dataForm!: ElForm;

    @Prop({required: true})
    parentDataId!: number;

    @Prop({required: true})
    dataId!: number;



    defaultData: PermissionDefinitionCreateOrUpdateDto = {
      id: undefined,
      parentId: undefined,
      name: '',
      displayName: '',
      url: '',
      permissionDefinitionScopes: [],
      icon: ''
    };

    formData: PermissionDefinitionCreateOrUpdateDto = {...this.defaultData};

    show = false;

    allPermissions :StringStringKeyValuePair[] = [];
    allScopes: SelectListItem[] = [];
    svgIcons: SvgIconItem[] = [];

    created() {
      this.fetchPermissionDefinitionScope()

      this.svgIcons = this.getSvgIcons();

      api.permissionDefinition.getAllPermissionNames().then(res => {
        this.allPermissions = res;
      })
    }

    fetchPermissionDefinitionScope() {
      api.enumService.getValues({typeName:'PermissionDefinitionScope'}).then(res=>{
        this.allScopes = res
      })
    }

    getSvgIcons() {
      return [{key: '404', value: '404.svg'},
        {key: 'back', value: 'back-top.svg'},
        {key: 'bug', value: 'bug.svg'},
        {key: 'chart', value: 'chart.svg'},
        {key: 'clipboard', value: 'clipboard.svg'},
        {key: 'component', value: 'component.svg'},
        {key: 'dashboard', value: 'dashboard.svg'},
        {key: 'documentation', value: 'documentation.svg'},
        {key: 'drag', value: 'drag.svg'},
        {key: 'edit', value: 'edit.svg'},
        {key: 'education', value: 'education.svg'},
        {key: 'email', value: 'email.svg'},
        {key: 'example', value: 'example.svg'},
        {key: 'excel', value: 'excel.svg'},
        {key: 'exit', value: 'exit-fullscreen.svg'},
        {key: 'eye', value: 'eye-off.svg'},
        {key: 'eye', value: 'eye-on.svg'},
        {key: 'form', value: 'form.svg'},
        {key: 'fullscreen', value: 'fullscreen.svg'},
        {key: 'guide', value: 'guide-2.svg'},
        {key: 'guide', value: 'guide.svg'},
        {key: 'hamburger', value: 'hamburger.svg'},
        {key: 'icon', value: 'icon.svg'},
        {key: 'international', value: 'international.svg'},
        {key: 'language', value: 'language.svg'},
        {key: 'like', value: 'like.svg'},
        {key: 'link', value: 'link.svg'},
        {key: 'list', value: 'list.svg'},
        {key: 'lock', value: 'lock.svg'},
        {key: 'message', value: 'message.svg'},
        {key: 'money', value: 'money.svg'},
        {key: 'nested', value: 'nested.svg'},
        {key: 'password', value: 'password.svg'},
        {key: 'pdf', value: 'pdf.svg'},
        {key: 'people', value: 'people.svg'},
        {key: 'peoples', value: 'peoples.svg'},
        {key: 'qq', value: 'qq.svg'},
        {key: 'search', value: 'search.svg'},
        {key: 'shopping', value: 'shopping.svg'},
        {key: 'size', value: 'size.svg'},
        {key: 'skill', value: 'skill.svg'},
        {key: 'star', value: 'star.svg'},
        {key: 'tab', value: 'tab.svg'},
        {key: 'table', value: 'table.svg'},
        {key: 'theme', value: 'theme.svg'},
        {key: 'tree', value: 'tree-table.svg'},
        {key: 'tree', value: 'tree.svg'},
        {key: 'user', value: 'user.svg'},
        {key: 'wechat', value: 'wechat.svg'},
        {key: 'zip', value: 'zip.svg'}];
    }

    @Watch('show')
    onShowChange(value: boolean) {
      if (value) {
        if (this.dataId) {
          api.permissionDefinition.get({id: this.dataId}).then(res => {
            this.formData = {...res};
          })
        } else {
          this.formData = {...this.defaultData};
        }
      } else {
        this.formData = {...this.defaultData};
      }

      this.$nextTick(() => {
        this.dataForm.clearValidate();
      });
    }


    querySearch(queryString: string, callback: Function) {

      const lowerCaseOfQueryString = queryString ? queryString.toLowerCase() : undefined;

      const result = lowerCaseOfQueryString ? this.allPermissions?.filter(s => s.key!.toLowerCase().indexOf(lowerCaseOfQueryString) >= 0) : this.allPermissions;
      return callback(result);
    }

    handleSelect(item: StringStringKeyValuePair) {
      const filterResult = this.allPermissions?.filter(s => s.key == item.key);
      if (filterResult != null && (filterResult?.length ?? 0) > 0) {
        this.formData.displayName = filterResult[0].value ?? '';
      }

    }

    searchIcon(queryString: string, callback: Function) {
      const lowerCaseOfQueryString = queryString ? queryString.toLowerCase() : undefined;

      const result = lowerCaseOfQueryString ? this.svgIcons?.filter(s => s.key!.toLowerCase().indexOf(lowerCaseOfQueryString) >= 0) : this.svgIcons;
      return callback(result);
    }

    handleIconSelect(item: SvgIconItem) {
      const filterResult = this.svgIcons.filter(s => s.key == item.key);
      if (filterResult && filterResult.length) {
        this.formData.icon = item.key;
      }
    }

    async save() {
      if (!this.formData.id) {
        this.formData.parentId = this.parentDataId === 0 ? undefined : this.parentDataId;

      }

      (this.$refs.dataForm as any).validate(async (valid: boolean) => {
        if (valid) {
          if (this.formData.id) {
            await api.permissionDefinition.update({body: this.formData});
          } else {
            await api.permissionDefinition.create({body: this.formData});
          }
          this.show = false;
          this.$message.success("更新成功");
          this.$emit("save-success");
        }
      });


    }

    cancel() {
      this.show = false;
    }

    rules = {
      name: [
        {required: true, message: '必须项'}
      ],
      displayName: [
        {required: true, message: '显示名称是必须的', trigger: ['blur', 'change']}
      ]
    }


  }
