
import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import {
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import EditData from "@/views/admin/dataDictionaries/edit.vue";

@Component({
  name: "RoleList",
  components: {
    EditData,
    PagedTableView
  }
})
export default class RoleList extends Vue {
  tableItems: DataDictionaryDto[] = [];
  valueList: DataDictionaryDto[] = [];
  table: any = {
    listLoading: true,
    page: 1,
    totalCount: 0,
    pageSize: 0,
    sorting: ""
  };

  queryForm = {
    keyword: ""
  };

  currentKey?: number = 0;
  editDataId: number = 0;
  editParentId: number = 0;

  async created() {
    await this.fetchData({
      isParent: true
    });
  }

  sort(e: any) {
    // if (e.prop && e.order) {
    //   this.queryForm.Sorting = `${e.prop} ${e.order}`;
    // } else {
    //   this.queryForm.Sorting = "id descending";
    // }
    // this.fetchData(1);
  }

  // 获取表数据
  async fetchData(params: any) {
    return api.dataDictionary.getAll(params).then(res => {
      this.table.listLoading = false;
      this.tableItems = res.items!;
      this.table.totalCount = res.totalCount!;
      console.log(res);
    });
  }

  showRow(row: DataDictionaryDto) {
    this.currentKey = row.id;
    api.dataDictionary
      .getListByParentId({
        parentId: row.id
      })
      .then(res => {
        this.valueList = res.items!;
      });
  }

  showRow2(row: DataDictionaryDto) {
    this.currentKey = row.parentId;
    api.dataDictionary
      .getListByParentId({
        parentId: row.parentId
      })
      .then(res => {
        this.valueList = res.items!;
      });
  }

  onSave(e: any) {
    console.log("success save");
  }

  handleCreateNewKey() {
    this.editDataId = 0;
    this.editParentId = 0;
    (this.$refs.editForm as any).show = true;
  }
  handleCreateNewValue() {
    this.editDataId = 0;
    this.editParentId = this.currentKey ?? 0;
    (this.$refs.editForm as any).show = true;
  }

  // 编辑
  handleEdit(index: number, row: DataDictionaryDto) {
    this.editDataId = row.id!;
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).form = row;

    console.warn("%cu must reWrite this method", "color:#0048BA;");
  }

  handleKeyDelete(index: number, row: DataDictionaryDto) {
    const id = row.id;
    this.$confirm("您确认要删除此字典项吗", "删除提示").then(() => {
      api.dataDictionary
        .delete({
          id: id
        })
        .then(res => {
          console.log("delete then");
          this.$message({
            type: "success",
            message: "删除成功"
          });
          this.fetchData({ isParent: true });
        })
        .catch(error => {
          console.log("delete catch");
        });
    });
  }

  handleValueDelete(index: number, row: DataDictionaryDto) {
    const id = row.id;
    this.$confirm("您确认要删除此字典值吗", "删除提示").then(() => {
      api.dataDictionary
        .delete({
          id: id
        })
        .then(res => {
          console.log("delete then");
          this.$message({
            type: "success",
            message: "删除成功"
          });
          if ((row.parentId ?? 0) > 0) {
            this.showRow(row);
          } else {
            this.fetchData({
              isParent: true
            });
          }
        })
        .catch(error => {
          console.log("delete catch");
        });
    });
  }

  handelOnSaved(dto: DataDictionaryDto) {
    if ((dto.parentId ?? 0) > 0) {
      this.showRow2(dto);
    } else {
      this.fetchData({
        isParent: true
      });
    }
    //this.fetchData();
  }
}
