
  import {Component, Vue} from "vue-property-decorator";
  import api from '@/api';
  import {PermissionDefinitionDto, PermissionDefinitionScope} from "@/api/appService";
  import {createTableTree, createTree} from "@/utils/tree";
  import PermissionDefinitionEdit from "@/views/admin/permission/definition/edit.vue";
  import FastCreatePermission from "@/views/admin/permission/definition/fastCreatePermission.vue";


  @Component({
    components:{
      FastCreatePermission,
      PermissionDefinitionEdit
    }
  })
  export default class PermissionDefinitionMaintain extends Vue{

    tableItems:PermissionDefinitionDto[] = [];
    isTableLoading= false;

    currentDataId?:number = 0;
    parentDataId:number  = 0;
    isFunctionShow:boolean = true;

    currentPermission:PermissionDefinitionDto = {};

    async created(){
      await this.fetchData();
    }

    formatScopes(row:PermissionDefinitionDto){
      if(!row.permissionDefinitionScopes){
        return ''
      }
      let result:string[] = [];
      row.permissionDefinitionScopes.forEach((scope)=>{
        switch (scope) {
          case PermissionDefinitionScope.Partner:
            result.push('合作方');
            break;
          case PermissionDefinitionScope.Foundation:
            result.push('基金会');
            break;
          default:
            result.push(scope);
            break;
        }
      });
      return result.join(',');
    }

    handleFastCreate(row:PermissionDefinitionDto){
      this.currentPermission = row;
      (this.$refs.fastCreatePermission as any).show = true;
    }

    async fetchData(){
      this.isTableLoading = true;
      await api.permissionDefinition.getAll({maxResultCount:3234235,sorting:'displayOrder ascending'}).then(res=>{
        let allData = res.items!;
        if(this.isFunctionShow === false){
          allData = allData.filter(s=>s.isHiddenForMenu === false);
        }
        const tree = createTableTree(allData,
          'parentId',
          'id',
          null,
          'children');


        this.tableItems = tree;
        this.isTableLoading = false;
      })
    }

    handleCreateChild(row:PermissionDefinitionDto){
      this.currentDataId = undefined;
      this.parentDataId = row.id!;
      (this.$refs.permissionDefinitionEdit as any).show = true;

    }

    handleEdit(row:PermissionDefinitionDto){
      this.currentDataId = row.id;
      (this.$refs.permissionDefinitionEdit as any).show = true;
    }

    handleCreate(){
      this.parentDataId = 0;
      this.currentDataId = undefined;
      (this.$refs.permissionDefinitionEdit as any).show = true;
    }

    handleSave(){
      this.fetchData();
    }

    handleFastCreateAllCompleted(){
      this.fetchData();
    }

    handleDelete(row:PermissionDefinitionDto){
      this.$confirm("你确定删除吗?", "提示").then(async () => {
        await api.permissionDefinition.delete({ id: row.id }).then(res => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.fetchData();
        });
      });
    }

    handleChangeFunctionShow(){
      this.isFunctionShow = !this.isFunctionShow;
      this.fetchData();
    }



  }
