
import {
  Component,
  Vue,
  Prop,
  Watch,
  Ref
} from "vue-property-decorator";

import api from "@/api";
import {
  GetRoleForEditOutput,
  RoleDto,
  PermissionDefinitionDto,
  SelectListItem, CreateRoleDto
} from "@/api/appService";
import {createNgTree, rebuildKeys, getKeys} from "@/utils/tree";
import {ElForm} from 'element-ui/types/form';

@Component({
  name: 'EditRole'
})
export default class EditRole extends Vue {
  @Ref() readonly dataForm!: ElForm;

  isProcessNodeChange: boolean = false;

  routesData: PermissionDefinitionDto[] = [];
  grantedPermissions: any[] = [];
  permissions: any[] = [];

  allScopes: SelectListItem[] = [];

  @Prop({required: true})
  dataId!: number;

  defaultData: CreateRoleDto | RoleDto = {
    name: undefined,
    displayName: undefined,
    normalizedName: undefined,
    description: undefined,
    grantedPermissions: [],
    id: 0,
    isDefault: false,
    isStatic: false,
    roleScopes: []
  };


  show = false;
  form: CreateRoleDto = {...this.defaultData};

  async created() {
    await this.getpermissionDefinitionAll()
    this.fetchRoleScopes();
  }

  get title() {
    if (this.dataId) {
      return `编辑 ${this.form.displayName}`;
    } else {
      return "新建";
    }
  }

  fetchRoleScopes() {
    api.enumService.getValues({typeName: 'PermissionDefinitionScope'}).then(res => {
      this.allScopes = res
    })
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog"
      });
      if (this.dataId) {
        await api.role
          .getRoleForEdit({id: this.dataId})
          .then((res: GetRoleForEditOutput) => {
            this.form = res.role!;
            if (!this.form.roleScopes) {
              this.form.roleScopes = [];
            }
            this.grantedPermissions = res.grantedPermissions!;
            this.permissions = res.permissions!;
            this.routesData = createNgTree(
              this.permissions,
              "parentId",
              "id",
              null,
              "children",
              "",
              false,
              'name'
            );
            console.log("routesData", this.routesData);
            const $tree = (this.$refs.tree as any);

            const rolePermissions = [] as any
            this.grantedPermissions.map(p => {
              let children = this.grantedPermissions.find(x => {
                if (x.parentId == p.id) {
                  return x;
                }
              })
              if (!children) {
                rolePermissions.push(p.name)
              }

            })

            $tree.setCheckedKeys(rolePermissions);
          })
          .finally(() => {
            setTimeout(() => {
              loading.close();
            }, 200);
          });
      } else {
        this.form = {...this.defaultData};
      }


    } else {
      this.form = {...this.defaultData};
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }


  async getpermissionDefinitionAll() {
    await api.permissionDefinition.getAll({maxResultCount: 33567}).then(res => {
      // this.allPermissions = res;
      this.routesData = createNgTree(
        res.items!,
        "parentId",
        "id",
        null,
        "children",
        "",
        false,
        'name'
      );
    })
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        let p = [];
        const $tree = this.$refs.tree as any;
        p = $tree.getCheckedNodes().map((item: any) => item.key);
        let halfKeys = $tree.getHalfCheckedKeys();
        if (halfKeys) {
          p = p.concat(halfKeys);
        }
        if (this.dataId) {
          await api.role.update({
            body: {...this.form, grantedPermissions: p}
          }).then(() => {
            this.show = false;
            this.$message.success("更新成功");
            this.$emit("onSave");
          });
        } else {
          await api.role.create({
            body: {...this.form, grantedPermissions: p}
          }).then(() => {
            this.show = false;
            this.$message.success("新建成功");
            this.$emit("onSave");
          });
        }
      }
    });
  }

  handlePermissionNodeCheck(node: any, checked: boolean) {
    if (this.isProcessNodeChange) {
      return;
    }
    this.isProcessNodeChange = true;
    const $tree = (this.$refs.tree as any);
    this.setAllChildrenChecked($tree, node, checked);

    // set parent checked
    let $node = $tree.getNode(node.key);
    while ($node.parent && $node.parent !== null && $node.parent.data && $node.parent.data.id) {
      let key = $node.parent.data.key;
      $tree.setChecked(key, true);
      $node = $tree.getNode(key);
    }

    setTimeout(() => {
      this.isProcessNodeChange = false;
    }, 100);
  }

  setAllChildrenChecked($tree: any, node: any, checked: boolean) {
    if (node.children && node.children.length > 0) {
      for (let i = 0; i < node.children.length; i++) {
        let child = node.children[i];
        $tree.setChecked(child.id, checked);
        this.setAllChildrenChecked($tree, child, checked);
      }
    }
  }

  cancel() {
    this.show = false;
  }

  roleRule = {
    name: [
      {
        required: true,
        message: "请输入角色编号",
        trigger: "blur"
      },
      {
        type: "string",
        pattern: "^([a-zA-Z]{1}[a-zA-Z0-9]{0,100})$",
        message: "角色编号仅能为字母与数字的组合，且只能以字母开头",
        trigger: "blur"
      },
      {
        max: 100,
        message: "最多100个字符",
        trigger: "blur"
      }
    ],
    displayName: [
      {
        required: true,
        message: "请输入角色名称",
        trigger: "blur"
      },
      {
        max: 100,
        message: "最多100个字符",
        trigger: "blur"
      }
    ],
    description: [
      {required: false},
      {
        max: 200,
        message: "最多200个字符"
      }
    ]
  };
}
